import React, { useState } from "react";
import { Colors } from "src/utils/colors";
import { StylesType } from "src/utils/types";

type Props = {
  search: (query: string) => Promise<void>;
  isLoading: boolean;
  query: string;
  setQuery: (newQuery: string) => void;
};

export const SearchBar = ({ search, isLoading, query, setQuery }: Props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const _handleKeyPress = event => {
    if (event.key === "Enter") {
      search(query);
    }
  };

  return (
    <div style={styles.container}>
      <label
        style={{
          ...styles.pressEnterLabel,
          opacity: isFocused && query ? 1 : 0
        }}
      >
        Press <span style={styles.enterBox}>Enter</span> to search
      </label>
      <input
        disabled={isLoading}
        style={{ ...styles.input, opacity: isLoading ? 0.7 : 1 }}
        onChange={e => setQuery(e.target.value)}
        value={query}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={_handleKeyPress}
        placeholder="What do you want to save money on?"
      />
    </div>
  );
};

const styles: StylesType = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    width: "100%"
  },
  input: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 50,
    borderRadius: 8,
    borderColor: Colors.lightGray,
    backgroundColor: Colors.white,
    outline: "none",
    borderWidth: 1,
    borderStyle: "solid",
    boxShadow: "0px 2px 12px rgba(0,0,0,0.07)",
    paddingLeft: 16,
    fontFamily: "DM Sans"
  },
  pressEnterLabel: {
    paddingBottom: 8,
    color: Colors.darkerGray,
    fontSize: 12
  },
  enterBox: {
    borderWidth: 0.8,
    borderStyle: "solid",
    borderColor: Colors.darkerGray,
    borderRadius: 4,
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 1,
    paddingBottom: 1,
    backgroundColor: Colors.white,
    boxShadow: "1px 1px 0px rgba(0,0,0,0.3)"
  }
};
