import React, { useEffect, useMemo, useState } from "react";
import { Popover } from "antd";
import { Colors } from "src/utils/colors";
import { StylesType } from "src/utils/types";
import { PastSearch } from "src/api/savings";
import { DateTime } from "luxon";
import { SiteConfig } from "src/utils/config";
import { Api } from "src/api";
import { isMobile } from "react-device-detect";

type Props = {
  isLoadingSearchResult: boolean;
  isLoggedIn: boolean;
};

export const ViewHistory = ({ isLoadingSearchResult, isLoggedIn }: Props) => {
  const [pastSearches, setPastSearches] = useState<PastSearch[]>([]);

  const _fetchSearchHistory = async () => {
    try {
      const res = await Api.savings.getSearchHistory();
      const { searches } = res.data;
      setPastSearches(searches);
    } catch (e) {
      if (SiteConfig.environment === "development") {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (!isLoadingSearchResult) {
      _fetchSearchHistory();
    }
  }, [isLoadingSearchResult]);

  const PopoverContent = useMemo(
    () => (
      <div style={styles.popoverContainer}>
        {pastSearches.map((search, i) => {
          const wasJustNow =
            DateTime.fromJSDate(new Date(search.searchedAt)).diffNow("minutes")
              .minutes >= -1;
          return (
            <div style={styles.searchItem}>
              <label style={styles.searchTitle}>{search.query}</label>
              <label style={styles.searchTimestamp}>
                {wasJustNow
                  ? "Just now"
                  : DateTime.fromJSDate(
                      new Date(search.searchedAt)
                    ).toRelative()}
              </label>
            </div>
          );
        })}
      </div>
    ),
    [pastSearches]
  );

  if (!isLoggedIn || isMobile || !pastSearches.length) {
    return null;
  }

  return (
    <Popover content={PopoverContent} trigger="click">
      <button style={styles.viewHistoryButton}>Past searches</button>
    </Popover>
  );
};

const styles: StylesType = {
  viewHistoryButton: {
    outline: "none",
    border: "none",
    color: Colors.darkerGray,
    cursor: "pointer",
    backgroundColor: Colors.transparent,
    fontSize: 14,
    fontFamily: "DM Sans",
    marginRight: 17
  },
  popoverContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: 230,
    maxHeight: 400,
    overflowY: "auto",
    overflowX: "hidden"
  },
  searchItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    borderBottomColor: Colors.lightGray,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    width: "100%"
  },
  searchTitle: {
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%"
  },
  searchTimestamp: {
    fontSize: 12,
    color: Colors.gray,
    width: "100%"
  }
};
