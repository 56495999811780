import * as firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBVPZWgG-PtIwZDPqJBfx0f0L4vqhC0zjM",
  authDomain: "prod-fizz-mobile-app.firebaseapp.com",
  projectId: "prod-fizz-mobile-app",
  storageBucket: "prod-fizz-mobile-app.appspot.com",
  messagingSenderId: "723858929555",
  appId: "1:723858929555:web:f7b20ab5efc2ec81c99a66",
  measurementId: "G-5G9B5J76S3"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
