import "src/index.css";

import React from "react";
import { createRoot } from "react-dom/client";

import App from "src/App";
import * as serviceWorker from "src/serviceWorker";
import { SiteConfig } from "src/utils/config";
import { initializeSentry } from "src/utils/initializeSentry";
import { RudderstackEventsEnum } from "src/utils/rudderstack";

declare global {
  interface Window {
    rudderanalytics: {
      identify: (uid: string, properties: { email?: string | null }) => void;
      page: () => void;
      reset: () => void;
      track: (
        eventName: RudderstackEventsEnum,
        properties?: Record<string, any>
      ) => void;
    };
  }
}

if (SiteConfig.environment === "production" && SiteConfig.sentryDsn) {
  initializeSentry();
}

const root = createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
