// helpful site for this:
// https://grayscale.design

export const Colors = {
  white: "#FFFFFF",
  darkerWhite: "#F6F6F6",
  lighterGray: "#d1d1d1",
  lightGray: "#E8E8E8",
  veryLightGray: "#F9F9F9",
  gray: "#969595",
  darkerGray: "#757575",
  darkGray: "#565656",
  black: "#000000",
  veryLightRed: "#ffe3e3",
  red: "#FF4C4C",
  orange: "orange",
  darkRed: "#8C0000",
  veryLightBlueishPurple: "#f0e8ff",
  blueishPurple: "#8757e6",
  blue: "#00A3FF",
  hoverBlue: "#2ab1ff",
  lightBlue: "#ccecff",
  green: "#00AE42",
  darkerGreen: "#00C092",
  transparent: "rgba(0,0,0,0)",
  canvasBackground: "#EBEBEB",

  green50: "#dfffe6",
  green100: "#b7ffc6",
  green200: "#42ff68",
  green300: "#00e92f",
  green400: "#00c327",
  green500: "#009e20",
  green600: "#007f19",
  green700: "#006f16",
  green800: "#005812",
  green900: "#00480e",

  blue50: "#f3f6ff",
  blue100: "#e9efff",
  blue200: "#d3deff",
  blue300: "#b6c8ff",
  blue400: "#87a5ff",
  blue500: "#547fff",
  blue600: "#265cff",
  blue700: "#0745ff",
  blue800: "#0034cf",
  blue900: "#002bab",

  purple50: "#f9f6ff",
  purple100: "#f3edfe",
  purple200: "#e7dafe",
  purple300: "#d5c0fd",
  purple400: "#b996fb",
  purple500: "#9d6bf9",
  purple600: "#8040f7",
  purple700: "#6f26f6",
  purple800: "#5108d7",
  purple900: "#4307b3",

  yellow50: "#f4f695",
  yellow100: "#f3f58a",
  yellow200: "#e6ea14",
  yellow300: "#cfd212",
  yellow400: "#afb20f",
  yellow500: "#8f910c",
  yellow600: "#6e700a",
  yellow700: "#616208",
  yellow800: "#4d4e07",
  yellow900: "#3f4005",

  pink50: "#fef4fb",
  pink100: "#fce9f7",
  pink200: "#fad4f0",
  pink300: "#f6b6e5",
  pink400: "#f082d3",
  pink500: "#e83ebb",
  pink600: "#c81799",
  pink700: "#b01587",
  pink800: "#8b106a",
  pink900: "#730e58",

  brown50: "#f9f6f3",
  brown100: "#f3ede7",
  brown200: "#e7dcd0",
  brown300: "#d5c3b0",
  brown400: "#b99e7f",
  brown500: "#9d7850",
  brown600: "#804320",
  brown700: "#6f381b",
  brown800: "#512812",
  brown900: "#431f0e",

  fizzPrimaryYellow: "#EFF31E",
  fizzBlack: "#1E2026",
  fizzDarkGray: "#2A2F35",
  fizzMediumDarkGray: "#2A2F35",
  fizzMediumGray: "#2A2F35",
  fizzYellowWarningLight: "#CED10C"
};
