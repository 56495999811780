import "src/screens/Search/index.css";
import React, { useState } from "react";
import { SavingsTip } from "src/api/savings";
import { StylesType } from "src/utils/types";
import { Colors } from "src/utils/colors";
import SendIcon from "src/assets/send.png";
import CheckmarkIcon from "src/assets/checkmark.png";
import { isMobile } from "react-device-detect";
import { Api } from "src/api";
import _ from "lodash";
import { AxiosError } from "axios";
import { SiteConfig } from "src/utils/config";

type Props = {
  result: SavingsTip;
};

export const Result = ({ result }: Props) => {
  const [hasSentText, setHasSentText] = useState<boolean>(false);

  const _textMe = async e => {
    e.stopPropagation();
    try {
      // optimistic update
      setHasSentText(true);
      await Api.savings.textMeTip(result.text, result.url);
    } catch (e) {
      alert("Something went wrong sending the text.");
      if (SiteConfig.environment === "development") {
        console.error(_.get(e, "response.data", (e as AxiosError).message));
      }
      setHasSentText(false);
    }
  };

  const _visitUrl = () => result.url && window.open(result.url, "_blank");

  return (
    <div style={styles.container}>
      <div style={styles.topContainer}>
        <label
          style={{
            ...styles.title,
            ...(result.url && {
              textDecoration: "underline",
              cursor: "pointer"
            })
          }}
          onClick={_visitUrl}
        >
          {result.title}
        </label>
        {isMobile ? null : (
          <button
            style={{
              ...styles.textMeButton,
              ...(hasSentText
                ? {
                    cursor: "default",
                    opacity: 0.4
                  }
                : {
                    cursor: "pointer"
                  })
            }}
            onClick={_textMe}
            disabled={hasSentText}
            className={
              hasSentText ? "text-me-button-sent" : "text-me-button-not-sent"
            }
          >
            <img
              src={hasSentText ? CheckmarkIcon : SendIcon}
              style={{
                ...styles.textButtonIcon,
                cursor: hasSentText ? "default" : "pointer"
              }}
              alt="textIcon"
            />
            <label
              style={{
                ...styles.textMeButtonLabel,
                cursor: hasSentText ? "default" : "pointer"
              }}
            >
              {hasSentText ? "Sent text" : "Text me tip"}
            </label>
          </button>
        )}
      </div>
      <p>{result.text}</p>
    </div>
  );
};

const styles: StylesType = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    color: Colors.darkGray,
    fontSize: 13,
    padding: 14,
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.lightGray,
    backgroundColor: Colors.white,
    marginBottom: 18,
    width: "100%"
  },
  title: {
    color: Colors.black,
    fontSize: 16,
    fontWeight: 500,
    width: isMobile ? "100%" : "80%"
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 8,
    width: "100%"
  },
  textMeButtonLabel: {
    color: Colors.fizzBlack,
    textAlign: "left",
    paddingLeft: 6,
    fontSize: 13
  },
  textButtonIcon: {
    width: 15,
    height: 15
  },
  textMeButton: {
    maxWidth: "20%",
    outline: "none",
    borderWidth: 0.6,
    borderStyle: "solid",
    borderColor: Colors.lighterGray,
    transition: "all 0.1s ease-out",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  }
};
