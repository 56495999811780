import { useEffect } from "react";

import { auth } from "src/utils/firebase";

export const SetupApp = () => {
  useEffect(() => {
    auth.onAuthStateChanged(fbUser => {
      if (fbUser) {
        window.rudderanalytics.identify(fbUser.uid, { email: fbUser.email });
      } else {
        window.rudderanalytics.reset();
      }
    });
  }, []);

  return null;
};
