import "antd/dist/reset.css";
import "semantic-ui-css/semantic.min.css";

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { RouteManager } from "src/RouteManager";
import { SetupApp } from "src/utils/SetupApp";

function App() {
  return (
    <Router>
      <SetupApp />
      <RouteManager />
    </Router>
  );
}

export default App;
