export const SiteConfig = {
  isServerRunningLocally:
    process.env.REACT_APP_IS_RUNNING_SERVER_LOCALLY === "true",
  localServerPortToConnectTo:
    process.env.REACT_APP_LOCAL_SERVER_PORT_TO_CONNECT_TO ?? "5200",
  prodServerUrl: process.env.REACT_APP_PROD_API_URL ?? "",
  environment: (process.env.REACT_APP_ENVIRONMENT ?? "development") as
    | "development"
    | "production",
  rudderstackWriteKey: process.env.REACT_APP_RUDDERSTACK_WRITE_KEY ?? "",
  sentryDsn: process.env.REACT_APP_SENTRY_DSN ?? "",
  isExclusiveToSubscribers:
    process.env.REACT_APP_IS_EXCLUSIVE_TO_SUBSCRIBERS === "true"
};
