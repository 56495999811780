import { AxiosResponse } from "axios";
import axios from "src/utils/axios";

const routeUrl = "/savings";

export type SuggestionSource = "PERSONALIZED" | "TRENDING" | "HISTORY";

export type SavingsTip = {
  title: string;
  text: string;
  url: string | null;
};

export type SavingsInputSuggestion = {
  title: string;
  source: SuggestionSource;
  logoUrl?: string;
};

export type PastSearch = {
  query: string;
  searchedAt: Date;
};

export const savings = {
  /**
   * Fetches tips for a specific merchant or expense.
   * @param target the merchant name or type of expense you want to find savings for (e.g. Uber or Rent)
   * @returns an array of tips
   */
  getTips: (target: string): Promise<AxiosResponse<{ tips: SavingsTip[] }>> =>
    axios.get(`${routeUrl}/tips`, {
      params: {
        target
      }
    }),
  /**
   * Fetches the user's most recent searches
   * @returns an array of past searches, with the most recent searches sorted to the beginning
   */
  getSearchHistory: (): Promise<AxiosResponse<{ searches: PastSearch[] }>> =>
    axios.get(`${routeUrl}/searches`),
  /**
   * Fetches suggested input merchants to search for tips for.
   * @returns an array of suggested inputs
   */
  getInputSuggestions: (): Promise<
    AxiosResponse<{ suggestions: SavingsInputSuggestion[] }>
  > => axios.get(`${routeUrl}/suggested-inputs`),
  /**
   * Sends the user a text containing the savings tip
   * @param tipText The tip text to send
   * @param tipUrl The tip url
   */
  textMeTip: (tipText: string, tipUrl: string | null) =>
    axios.post(`${routeUrl}/text-tip`, {
      tipText,
      tipUrl: tipUrl || undefined
    })
};
