import { setUser } from "@sentry/react";
import { useEffect, useState } from "react";
import { Api } from "src/api";
import { auth, getIsUserAuthed } from "src/utils/firebase";

export enum SubscriptionTier {
  FizzPremium = "fizz_premium"
}

// fyi more information than this is included in the response but we don't care about everything
export type User = {
  _id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber: string;
  activeSubscriptionTier: SubscriptionTier | null;
};

export const useMe = () => {
  const [me, setMe] = useState<User | null>(null);
  const [hasLoadedAtLeastOnce, setHasLoadedAtLeastOnce] =
    useState<boolean>(false);

  const fetchUser = async () => {
    try {
      if (!getIsUserAuthed()) {
        setMe(null);
        return;
      }
      const res = await Api.users.getMe();
      const { user } = res.data;
      setMe(user);
    } catch (e) {
      setMe(null);
      console.error(e);
    }
    setHasLoadedAtLeastOnce(true);
  };

  useEffect(() => {
    auth.onAuthStateChanged(fbUser => {
      if (fbUser) {
        fetchUser();
      } else {
        setHasLoadedAtLeastOnce(true);
        setUser(null);
      }
    });
  }, []);

  return { me, hasLoadedAtLeastOnce, fetchUser };
};
