import React, { useEffect, useState } from "react";
import { Colors } from "src/utils/colors";
import { auth } from "src/utils/firebase";
import { RudderstackEventsEnum } from "src/utils/rudderstack";
import { StylesType } from "src/utils/types";

type Props = {
  openSignInModal: () => void;
};

export const LogoutButton = ({ openSignInModal }: Props) => {
  const [isAuthed, setIsAuthed] = useState<boolean>(false);

  useEffect(() => {
    auth.onAuthStateChanged(fbUser => {
      if (fbUser) {
        setIsAuthed(true);
      } else {
        setIsAuthed(false);
      }
    });
  }, []);

  const _logoutOrLogin = async () => {
    if (isAuthed) {
      await auth.signOut();
      window.rudderanalytics.track(RudderstackEventsEnum.SignedOut);
      window.location.reload();
      return;
    }
    openSignInModal();
  };

  return (
    <button style={styles.signOutButton} onClick={_logoutOrLogin}>
      {isAuthed ? "Sign out" : "Sign in"}
    </button>
  );
};

const styles: StylesType = {
  signOutButton: {
    outline: "none",
    border: "none",
    color: Colors.darkerGray,
    cursor: "pointer",
    backgroundColor: Colors.transparent,
    fontSize: 14,
    fontFamily: "DM Sans"
  }
};
