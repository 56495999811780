import "src/screens/Search/index.css";

import React from "react";
import Modal from "react-modal";
import { Colors } from "src/utils/colors";
import { StylesType } from "src/utils/types";
import { isMobile } from "react-device-detect";
import { RudderstackEventsEnum } from "src/utils/rudderstack";
import { SiteConfig } from "src/utils/config";

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
};

export const FizzSubscriptionModal = ({
  isModalOpen,
  setIsModalOpen
}: Props) => {
  const _requestCloseModal = () => {
    setIsModalOpen(false);
  };

  const _learnMore = () => {
    window.rudderanalytics.track(
      RudderstackEventsEnum.ClickedLearnMoreAboutFizzPremium
    );
    window.open("https://www.joinfizz.com", "_blank");
  };

  if (!SiteConfig.isExclusiveToSubscribers) {
    return null;
  }

  return (
    <Modal
      isOpen={isModalOpen}
      style={{ content: styles.modal }}
      overlayClassName="overlay-custom"
      shouldCloseOnOverlayClick
      onRequestClose={_requestCloseModal}
      ariaHideApp={false}
    >
      <div style={styles.container}>
        <label style={styles.title}>
          Become a Fizz Premium member for access
        </label>
        <label style={styles.description}>
          Along with access to the Fizz Savings Finder, Fizz Premium will get
          you more cash back, advanced credit monitoring, spend tracking, 1-on-1
          financial coaching, personalized savings tips, and more. You can join
          Fizz Premium from the Fizz app.
        </label>
        <button style={styles.learnMoreButton} onClick={_learnMore}>
          Learn more
        </button>
      </div>
    </Modal>
  );
};

const styles: StylesType = {
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: 20,
    paddingRight: 20
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: "left",
    paddingBottom: 14,
    lineHeight: 1.2
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: "left",
    color: Colors.gray,
    paddingBottom: 24
  },
  modal: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 600,
    height: "80%",
    maxHeight: isMobile ? 350 : 280,
    borderRadius: 20,
    borderColor: Colors.lightGray
  },
  learnMoreButton: {
    outline: "none",
    border: "none",
    backgroundColor: Colors.fizzBlack,
    color: Colors.fizzPrimaryYellow,
    fontSize: 15,
    fontWeight: 500,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 8,
    fontFamily: "DM Sans",
    height: 50,
    cursor: "pointer"
  }
};
