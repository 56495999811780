import React from "react";
import { Route, Switch } from "react-router-dom";

import { SearchScreen } from "src/screens/Search";
import { StylesType } from "src/utils/types";

export const RouteManager = () => {
  return (
    <Switch>
      <div style={styles.container}>
        <div style={styles.innerContainer}>
          <Route path="/search" component={SearchScreen} />
          <Route
            exact
            path="/"
            component={() => {
              window.location.href = "/search";
              return null;
            }}
          />
        </div>
      </div>
    </Switch>
  );
};

const styles: StylesType = {
  container: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  }
};
