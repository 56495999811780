import React, { useCallback, useEffect, useState } from "react";
import { Api } from "src/api";
import { SavingsInputSuggestion, SuggestionSource } from "src/api/savings";
import { StylesType } from "src/utils/types";
import { Col, Row } from "react-flexbox-grid";
import FireIcon from "src/assets/fire.png";
import PastSearchIcon from "src/assets/search.png";
import { Colors } from "src/utils/colors";
import { isMobile } from "react-device-detect";
import { RudderstackEventsEnum } from "src/utils/rudderstack";
import { getSuggestionSubtitleFromType } from "../helpers";

type Props = {
  search: (query: string) => Promise<void>;
};

export const Suggestions = ({ search }: Props) => {
  const [suggestions, setSuggestions] = useState<SavingsInputSuggestion[][]>(
    []
  );

  const _fetchSuggestions = useCallback(async () => {
    try {
      const res = await Api.savings.getInputSuggestions();
      const { suggestions: fetchedSuggestions } = res.data;

      // put into pairs for rendering
      const pairs: SavingsInputSuggestion[][] = [];
      for (let i = 0; i < fetchedSuggestions.length; i += 2) {
        pairs.push(fetchedSuggestions.slice(i, i + 2));
      }
      setSuggestions(pairs);
    } catch (e) {
      console.error(e);
    }
  }, [setSuggestions]);

  const _search = (_query: string, source: SuggestionSource) => {
    window.rudderanalytics.track(
      RudderstackEventsEnum.ClickedSuggestedSearchInput,
      {
        property: {
          suggestion_name: _query,
          source
        }
      }
    );
    search(_query);
  };

  useEffect(() => {
    _fetchSuggestions();
  }, [_fetchSuggestions]);

  if (!suggestions.length) {
    return null;
  }

  return (
    <div style={styles.container}>
      <label style={styles.title}>Ideas for you</label>
      {suggestions.map((pair, index) => (
        <Row key={index} style={styles.row}>
          {pair.map((item, i) => (
            <Col key={`${item.title}_${i}`} style={{ width: "49%" }}>
              <button
                style={styles.suggestionButton}
                onClick={() => _search(item.title, item.source)}
                className="suggestion-item"
              >
                <div style={styles.imgContainer}>
                  <img
                    src={
                      item.logoUrl ??
                      (item.source === "HISTORY" ? PastSearchIcon : FireIcon)
                    }
                    style={styles.icon}
                    alt="SuggestionIcon"
                  />
                </div>
                <div style={styles.rightItemContainer}>
                  <label style={styles.itemTitle}>{item.title}</label>
                  {isMobile ? null : (
                    <label style={styles.itemDescription}>
                      {getSuggestionSubtitleFromType(item.source)}
                    </label>
                  )}
                </div>
              </button>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

const styles: StylesType = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    paddingTop: 24,
    paddingBottom: 10
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
    padding: 0,
    marginBottom: 10
  },
  suggestionButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 56,
    cursor: "pointer",
    outline: "none",
    borderColor: Colors.lightGray,
    borderWidth: 1,
    borderStyle: "solid",
    width: "100%",
    borderRadius: 8
  },
  imgContainer: {
    width: 30,
    height: 30,
    cursor: "pointer",
    borderRadius: 100,
    overflow: "hidden",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.lightGray,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: isMobile ? 0 : 10
  },
  icon: {
    width: 20,
    height: 20,
    cursor: "pointer"
  },
  rightItemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    cursor: "pointer",
    paddingLeft: 12,
    marginRight: 10
  },
  itemTitle: {
    fontSize: 13,
    fontWeight: 500,
    cursor: "pointer"
  },
  itemDescription: {
    fontSize: 12,
    color: Colors.gray,
    cursor: "pointer",
    paddingTop: 4
  },
  title: {
    fontSize: 15,
    paddingBottom: 12,
    fontWeight: 500
  }
};
