import React from "react";
import { SavingsTip } from "src/api/savings";
import { StylesType } from "src/utils/types";
import { Result } from "src/screens/Search/components/Result";
import { Colors } from "src/utils/colors";

type Props = {
  results: SavingsTip[];
};

export const ResultsList = ({ results }: Props) => {
  return (
    <div style={styles.container}>
      <label style={styles.title}>Tips we found</label>
      {results.length ? (
        results.map((result, i) => <Result result={result} key={i} />)
      ) : (
        <label style={styles.noResultsLabel}>
          No results found. Please try a different search.
        </label>
      )}
    </div>
  );
};

const styles: StylesType = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingTop: 18,
    width: "100%",
    paddingBottom: 80
  },
  title: {
    fontSize: 15,
    paddingBottom: 12,
    fontWeight: 500
  },
  noResultsLabel: {
    color: Colors.gray
  }
};
