import "react-phone-number-input/style.css";
import React, { useState } from "react";
import _ from "lodash";
import { AxiosError } from "axios";
import { SavingsTip } from "src/api/savings";
import { useMe } from "src/utils/useMe";
import { Api } from "src/api";
import { RudderstackEventsEnum } from "src/utils/rudderstack";
import { StylesType } from "src/utils/types";
import { SearchBar } from "src/screens/Search/components/SearchBar";
import { Suggestions } from "src/screens/Search/components/Suggestions";
import FizzLogo from "src/assets/fizzLogo.svg";
import { Colors } from "src/utils/colors";
import { ResultsList } from "src/screens/Search/components/ResultsList";
import { Loading } from "src/screens/Search/components/Loading";
import { LoginModal } from "src/screens/Search/components/LoginModal";
import { LogoutButton } from "src/screens/Search/components/LogoutButton";
import { FizzSubscriptionModal } from "src/screens/Search/components/FizzSubscriptionModal";
import { isAndroid, isMobile } from "react-device-detect";
import { userRequestsSuicide } from "src/screens/Search/helpers";
import { ViewHistory } from "./components/ViewHistory";
import { SiteConfig } from "src/utils/config";

const APP_STORE_LINK = isAndroid
  ? "https://play.google.com/store/apps/details?id=com.fizz.app.production&hl=en_US&gl=US"
  : "https://apps.apple.com/us/app/fizz-the-student-card/id1629069157";
const SUICIDE_HELP_MESSAGE = "Brighter days are ahead, hang in there :)";

export const SearchScreen = () => {
  const { me, hasLoadedAtLeastOnce } = useMe();
  const [query, setQuery] = useState<string>("");
  const [lastSearchedQuery, setLastSearchedQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [results, setResults] = useState<SavingsTip[]>([]);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const [isSubscriberModalOpen, setIsSubscriberModalOpen] =
    useState<boolean>(false);

  const search = async (_query: string) => {
    try {
      if (!_query || isLoading) {
        return;
      }

      if (_query !== query) {
        setQuery(_query);
      }

      window.rudderanalytics.track(RudderstackEventsEnum.AttemptedSearch, {
        is_subscriber: !!me?.activeSubscriptionTier,
        is_logged_in: !!me,
        query: _query
      });

      if (!me) {
        setIsLoginModalOpen(true);
        return;
      }

      if (!me.activeSubscriptionTier && SiteConfig.isExclusiveToSubscribers) {
        // open popup telling them to subscribe to fizz premium
        setIsSubscriberModalOpen(true);
        return;
      }

      setResults([]);
      setIsLoading(true);
      setLastSearchedQuery(_query);
      setErrorMessage("");

      if (userRequestsSuicide(_query)) {
        throw new Error(SUICIDE_HELP_MESSAGE);
      }

      const res = await Api.savings.getTips(_query);
      const { tips } = res.data;
      setResults(
        tips.map(tip => ({
          title: tip.title.replace(/["']/g, ""),
          text: tip.text.replace(/["']/g, ""),
          url: tip.url
        }))
      );
    } catch (e) {
      setErrorMessage(_.get(e, "response.data", (e as AxiosError).message));
    }
    setIsLoading(false);
  };

  if (!hasLoadedAtLeastOnce) {
    return null;
  }

  return (
    <div style={styles.outerContainer}>
      <LoginModal
        isModalOpen={isLoginModalOpen}
        setIsModalOpen={setIsLoginModalOpen}
      />
      <FizzSubscriptionModal
        isModalOpen={isSubscriberModalOpen}
        setIsModalOpen={setIsSubscriberModalOpen}
      />
      <div style={styles.topRightContainer}>
        <ViewHistory isLoadingSearchResult={isLoading} isLoggedIn={!!me} />
        <LogoutButton openSignInModal={() => setIsLoginModalOpen(true)} />
      </div>
      <img src={FizzLogo} style={styles.fizzLogo} alt="FizzLogo" />
      <div style={styles.container}>
        <div style={styles.titleContainer}>
          <label style={styles.title}>
            {me?.firstName
              ? `Hey ${me.firstName}, let's find you ways to save.`
              : "Find ways to save at 5,000,000+ places."}
          </label>
          {me ? null : SiteConfig.isExclusiveToSubscribers ? (
            <label style={styles.exclusiveLabel}>
              Exclusive to Fizz Premium members.
            </label>
          ) : (
            <label style={styles.exclusiveLabel}>
              Download the Fizz app{" "}
              <a
                style={styles.appStoreLink}
                href={APP_STORE_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{" "}
              to gain access.
            </label>
          )}
        </div>
        <SearchBar
          search={search}
          isLoading={isLoading}
          query={query}
          setQuery={setQuery}
        />
        {isLoading ? (
          <Loading />
        ) : errorMessage ? (
          <label style={styles.errorMessage}>
            {errorMessage === SUICIDE_HELP_MESSAGE
              ? SUICIDE_HELP_MESSAGE
              : "Something went wrong with your search."}
          </label>
        ) : (
          <>
            <Suggestions search={search} />
            {lastSearchedQuery ? <ResultsList results={results} /> : null}
          </>
        )}
      </div>
    </div>
  );
};

const styles: StylesType = {
  outerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    position: "relative"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "90%",
    maxWidth: 600,
    paddingTop: 130
  },
  title: {
    fontSize: 26,
    fontWeight: 600,
    textAlign: "center",
    lineHeight: 1.3
  },
  fizzLogo: {
    width: 50,
    position: "absolute",
    top: isMobile ? 22 : 30,
    left: isMobile ? 22 : 30
  },
  errorMessage: {
    paddingTop: 50,
    color: Colors.red,
    width: "100%",
    textAlign: "center"
  },
  exclusiveLabel: {
    fontSize: 15,
    color: Colors.gray,
    textAlign: "center",
    paddingTop: 12
  },
  titleContainer: {
    paddingBottom: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  topRightContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "absolute",
    top: isMobile ? 25 : 20,
    right: 25
  },
  appStoreLink: {
    color: Colors.gray,
    textDecoration: "underline",
    cursor: "pointer"
  }
};
