import { getAuth } from 'firebase/auth';
import firebase from 'src/firebaseConfig';

export const auth = getAuth(firebase.getApp());

export async function getAuthToken(): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      currentUser.getIdToken().then(resolve).catch(reject);
    } else {
      return resolve(null);
    }
  });
}

export const getIsUserAuthed = () => !!auth.currentUser
