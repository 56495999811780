import React from "react";
import { StylesType } from "src/utils/types";
import { BounceLoader } from "react-spinners";
import { Colors } from "src/utils/colors";

export const Loading = () => (
  <div style={styles.container}>
    <BounceLoader
      size={35}
      color={Colors.fizzYellowWarningLight}
      speedMultiplier={0.7}
    />
    <label style={styles.loadingMessage}>
      Tips may take up to a minute to find. Please do not close the tab.
    </label>
  </div>
);

const styles: StylesType = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingTop: 86
  },
  loadingMessage: {
    fontSize: 14,
    color: Colors.black,
    textAlign: "center",
    paddingTop: 25
  }
};
