import axios from "axios";
import { getAuth } from "firebase/auth";
import firebase from "src/firebaseConfig";

import { SiteConfig } from "./config";

const LOCAL_SERVER_URL = `http://localhost:${SiteConfig.localServerPortToConnectTo}`;
export const PROD_SERVER_URL = SiteConfig.prodServerUrl;
export const serverSlug = "/api/v2/savings-site";

const axiosWrapper = axios.create({
  baseURL: SiteConfig.isServerRunningLocally
    ? LOCAL_SERVER_URL + serverSlug
    : PROD_SERVER_URL + serverSlug,
  timeout: 90000
});

axiosWrapper.interceptors.request.use(
  async (config: any) => {
    const token = await getAuth(firebase.getApp()).currentUser?.getIdToken();
    config.headers.Authorization = `Bearer ${token || ""}`;
    return config;
  },
  (error: Error) => Promise.reject(error)
);

export default axiosWrapper;
