import axios from "src/utils/axios";

const routeUrl = "/users";

export const users = {
  getMe: () => axios.get(`${routeUrl}/me`),
  submitPhoneNumber: (phoneNumber: string) =>
    axios.post(`${routeUrl}/phone/send-code`, {
      phoneNumber,
      isLogin: true
    }),
  submitPhoneVerificationCode: (phoneNumber: string, code: string) =>
    axios.post(`${routeUrl}/phone/verify-code`, {
      phoneNumber,
      code
    })
};
