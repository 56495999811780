import { SuggestionSource } from "src/api/savings";

export const userRequestsSuicide = (input: string) => {
  const lowercasedInput = input.toLowerCase();
  return (
    lowercasedInput.includes("suicide") ||
    lowercasedInput.includes("self-harm") ||
    lowercasedInput.includes("self harm") ||
    lowercasedInput.includes("kill myself")
  );
};

export const getSuggestionSubtitleFromType = (source: SuggestionSource) => {
  switch (source) {
    case "HISTORY":
      return "Your recent search";
    case "PERSONALIZED":
      return "Based on your spending";
    case "TRENDING":
      return "Trending right now";
    default:
      return "Idea";
  }
};
