import "src/screens/Search/index.css";

import { AxiosError } from "axios";
import { signInWithCustomToken } from "firebase/auth";
import _ from "lodash";
import React, { useState } from "react";
import Modal from "react-modal";
import PhoneInput from "react-phone-number-input";
import { Api } from "src/api";
import { Colors } from "src/utils/colors";
import { auth } from "src/utils/firebase";
import { StylesType } from "src/utils/types";
import { isMobile } from "react-device-detect";
import { RudderstackEventsEnum } from "src/utils/rudderstack";
import { SiteConfig } from "src/utils/config";

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
};

export const LoginModal = ({ isModalOpen, setIsModalOpen }: Props) => {
  const [hasSubmittedPhoneNumber, setHasSubmittedPhoneNumber] =
    useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const canSubmitPhoneNumber = phoneNumber.length === 12 && !isLoading;
  const canSubmitCode = code.length === 6 && !isLoading;
  const canSubmit = hasSubmittedPhoneNumber
    ? canSubmitCode
    : canSubmitPhoneNumber;

  const _submitPhoneNumberOrCode = async () => {
    try {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      setErrorMessage("");
      if (hasSubmittedPhoneNumber) {
        const res = await Api.users.submitPhoneVerificationCode(
          phoneNumber,
          code
        );
        const { loginToken, isValidated } = res.data;
        if (!isValidated) {
          throw new Error("The code you entered is incorrect.");
        }
        await signInWithCustomToken(auth, loginToken);
        setIsModalOpen(false);
        window.rudderanalytics.track(RudderstackEventsEnum.SignedIn);
        window.location.reload();
      } else {
        await Api.users.submitPhoneNumber(phoneNumber);
        setHasSubmittedPhoneNumber(true);
        window.rudderanalytics.track(RudderstackEventsEnum.EnteredPhoneNumber);
      }
    } catch (e) {
      setErrorMessage(_.get(e, "response.data", (e as AxiosError).message));
    }
    setIsLoading(false);
  };

  const _changePhoneNumber = () => {
    setPhoneNumber("");
    setCode("");
    setHasSubmittedPhoneNumber(false);
    setErrorMessage("");
  };

  const _requestCloseModal = () => {
    setIsModalOpen(false);
    setHasSubmittedPhoneNumber(false);
    setPhoneNumber("");
    setCode("");
    setIsLoading(false);
    setErrorMessage("");
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={{ content: styles.modal }}
      overlayClassName="overlay-custom"
      shouldCloseOnOverlayClick
      onRequestClose={_requestCloseModal}
      ariaHideApp={false}
    >
      <div style={styles.container}>
        <label style={styles.title}>
          {hasSubmittedPhoneNumber ? "Enter code" : "Sign in for access"}
        </label>
        <label style={styles.description}>
          {hasSubmittedPhoneNumber
            ? `Enter the six-digit verification code we sent to ${phoneNumber}.`
            : `Enter the phone number of your Fizz account and we'll send you a six-digit verification code.${
                SiteConfig.isExclusiveToSubscribers
                  ? "You must be a Fizz Premium member to use the Fizz Savings Finder."
                  : ""
              }`}
        </label>
        {hasSubmittedPhoneNumber ? (
          <button style={styles.changePhoneButton} onClick={_changePhoneNumber}>
            Use different phone number
          </button>
        ) : null}
        {errorMessage ? (
          <label style={styles.errorMessage}>{errorMessage}</label>
        ) : null}
        <div style={styles.bottomContainer}>
          {hasSubmittedPhoneNumber ? (
            <input
              value={code}
              onChange={e => setCode(e.target.value)}
              style={styles.codeInput}
              placeholder="123456"
            />
          ) : (
            <PhoneInput
              international={false}
              defaultCountry="US"
              addInternationalOption={false}
              countries={["US"]}
              onChange={value => setPhoneNumber(value?.toString() ?? "")}
              value={phoneNumber}
              placeholder="(444) 444-4444"
              numberInputProps={{ style: styles.phoneNumberInput }}
              style={styles.phoneNumberInputContainer}
              disabled={isLoading}
            />
          )}
          <button
            style={{
              ...styles.submitButton,
              opacity: canSubmit ? 1 : 0.3,
              cursor: canSubmit ? "pointer" : "default"
            }}
            onClick={_submitPhoneNumberOrCode}
            disabled={!canSubmit}
          >
            {isLoading
              ? "One moment..."
              : hasSubmittedPhoneNumber
              ? "Sign in"
              : "Send code"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const styles: StylesType = {
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: 20,
    paddingRight: 20
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: "left",
    paddingBottom: 14,
    lineHeight: 1.2
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: "left",
    color: Colors.gray,
    paddingBottom: 24
  },
  modal: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 550,
    height: "80%",
    maxHeight: isMobile ? 380 : 280,
    borderRadius: 20,
    borderColor: Colors.lightGray
  },
  phoneNumberInput: {
    outline: "none",
    border: "none",
    fontFamily: "DM Sans",
    fontSize: 15
  },
  phoneNumberInputContainer: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.lightGray,
    borderStyle: "solid",
    padding: 14,
    height: 50
  },
  bottomContainer: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "flex-start",
    alignItems: isMobile ? "flex-start" : "center"
  },
  codeInput: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.lightGray,
    borderStyle: "solid",
    paddingLeft: 14,
    height: 50,
    outline: "none",
    fontSize: 15,
    fontFamily: "DM Sans"
  },
  submitButton: {
    outline: "none",
    border: "none",
    backgroundColor: Colors.fizzBlack,
    color: Colors.fizzPrimaryYellow,
    fontSize: 15,
    fontWeight: 500,
    marginLeft: isMobile ? 0 : 12,
    height: 50,
    marginTop: isMobile ? 12 : 0,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 8,
    fontFamily: "DM Sans"
  },
  changePhoneButton: {
    backgroundColor: Colors.transparent,
    marginLeft: isMobile ? -12 : -6,
    color: Colors.gray,
    textDecoration: "underline",
    fontSize: 14,
    marginBottom: 12,
    cursor: "pointer",
    outline: "none",
    border: "none",
    fontFamily: "DM Sans"
  },
  errorMessage: {
    color: Colors.red,
    marginBottom: 12,
    width: "100%"
  }
};
